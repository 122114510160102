export default function (source, url, args = {}) {
  const isUnsplashImg = source === 'unsplash'

  const { crop } = args

  if (isUnsplashImg) {
    url = url.replace('crop=entropy&', '')
    const queryString = Object.entries(args)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')
    return `${url}&${queryString}`
  }

  delete args.crop
  delete args.fit

  let queryString = Object.entries(args)
    .map(([key, val]) => `${key}-${val}`)
    .join(',')

  if (crop) {
    queryString += `,fo-${crop.replace(',', '_')}`
  }

  // Src from imagekit
  return `${url}?tr=${queryString}`
}
