<template>
  <Item :item="item" :parent="parent" :class="itemClasses">
    <figure v-bind="imgWrapperBindings">
      <SvgClipPath
        v-if="hasBlob"
        :clip-path-id="item.uid"
        :path="blobPath"
        :size="400"
      />

      <img
        v-if="imgExists"
        :key="bgImgLazyObj.src"
        v-lazy="bgImgLazyObj"
        :width="imgWidth"
        :height="imgHeight"
        :alt="altText"
        v-bind="imgBindings"
      />
    </figure>

    <is-admin>
      <p
        v-if="!imgExists"
        class="
          flex
          overflow-hidden
          justify-center
          items-center
          py-4
          w-full
          h-full
          whitespace-nowrap
          bg-gray-200
          rounded
          text-sm text-center text-gray-600
          //
        "
      >
        No image selected or uploaded.
      </p>
    </is-admin>
  </Item>
</template>

<script>
import imgProxy from '@/utils/imgProxy'
import itemMixin from '@/mixins/item'
import shapes from '@/config/shapes'

export default {
  name: 'ImageItem',

  components: {
    SvgClipPath: () => import('@/components/SvgClipPath'),
    // SvgBlob: () => import('@/components/SvgBlob'),
    Item: () => import('@/components/Item'),
  },

  mixins: [itemMixin],

  props: {
    dimensions: {
      type: Object,
      default() {
        return {}
      },
    },

    imgClass: {
      type: Object,
      default: () => ({}),
    },

    appearance: {
      type: [String, Object],
      default: null,
    },

    effect: {
      type: String,
      default: null,
    },

    aspectRatio: {
      type: String,
      default: null,
    },

    crop: {
      type: String,
      default: 'center',
    },
  },

  data: () => ({
    shapes,
  }),

  computed: {
    imgExists() {
      const { url, filename } = this.item.settings ?? {}
      return url || filename
    },

    imgMaxWidth() {
      return this.dimensions.width
    },

    imgMaxHeight() {
      return this.dimensions.height
    },

    imgOrgWidth() {
      return this.item.settings?.width
    },

    imgOrgHeight() {
      return this.item.settings?.height
    },

    imgWidth() {
      // If appearance is fill - dont return width
      // img-ratio and object-fit doesnt play well

      // If no org width exists or org width is more than max width - Use maxwidth
      // If org width is less than max width - use org width
      return !this.imgOrgWidth || this.imgOrgWidth > this.imgMaxWidth
        ? this.imgMaxWidth
        : this.imgOrgWidth
    },

    imgHeight() {
      const org = this.imgOrgHeight
      const max = this.imgMaxHeight
      if (!org) {
        return max
      }

      return org > max
        ? Math.round((org / this.imgOrgWidth) * this.imgMaxWidth)
        : org
    },

    hasShape() {
      return (
        typeof this.appearance === 'string' &&
        this.appearance.startsWith('shape')
      )
    },

    hasBlob() {
      return (
        typeof this.appearance === 'string' &&
        this.appearance.startsWith('blob')
      )
    },

    shape() {
      if (this.hasShape) {
        return this.appearance.split('@')[1]
      }

      return null
    },

    clipPath() {
      if (this.hasShape) {
        return this.shapes.find(
          ({ key }) => key === this.appearance.split('@')[1]
        )?.clipPath
      }

      if (this.hasBlob) {
        return `url(#${this.item.uid})`
      }

      return null
    },

    blobPath() {
      if (!this.hasBlob) {
        return
      }

      return this.appearance.split('@')[1]
    },

    isSvg() {
      if (!this.imgExists) {
        return null
      }
      const fileName = this.item.settings.filename ?? ''
      return fileName.endsWith('.svg')
    },

    bgImgLazyObj() {
      if (!this.imgExists) {
        return null
      }

      const { filename, url: imgUrl, source } = this.item.settings

      const url = filename ? `${this.$config.imageProxyUrl}${filename}` : imgUrl

      let fit = 'clip'
      const args = {}

      if (!this.imgOrgWidth || this.imgOrgWidth > this.imgMaxWidth) {
        args.w = this.imgMaxWidth
      }

      if (!this.imgOrgHeight) {
        args.h = this.imgMaxHeight
        fit = 'crop'
      }

      const loadArgs = { w: 100 }

      if (this.hasShape || this.hasBlob) {
        args.crop = this.crop.replace('-', ',')
        fit = 'crop'
        args.h = this.imgMaxWidth
        loadArgs.h = 100
      }

      args.fit = fit
      loadArgs.fit = fit

      return {
        src: imgProxy(source, url, args),
        loading: imgProxy(source, url, loadArgs),
      }
    },

    altText() {
      return this.item.settings?.altText ?? ''
    },

    effectStyle() {
      const effect = this.item.props.effect

      if (!effect) {
        return
      }

      return {
        bw: { filter: 'grayscale(100%)' },
        blend: { 'mix-blend-mode': 'luminosity' },
      }[effect]
    },

    itemClasses() {
      return {
        'rounded-full': this.shape === 'circle',
        'w-1/3': this.width === '1:3',
        'w-1/2': this.width === '1:2',
        'w-2/3': this.width === '2:3',
      }
    },

    width() {
      return this.item.props.width
    },

    appearanceClass() {
      if (this.hasShape || this.hasBlob) {
        return 'mx-auto'
      }

      return {
        fill: `lg:absolute top-0 bottom-0 right-0 left-0 object-cover object-${this.crop}`,
      }[this.appearance]
    },

    imgBindings() {
      return {
        class: [
          this.appearanceClass,
          this.imgClass,
          { 'rounded-full': this.shape === 'circle' },
          { 'max-h-full': !this.isSvg },
          { 'w-full h-full': this.appearance === 'fill' },
        ],
        style: {
          aspectRatio: this.hasBlob || this.hasShape ? `1/1` : undefined,
          clipPath: this.clipPath,
          transition: 'clip-path 0.5s ease-out',
        },
      }
    },

    imgWrapperBindings() {
      return {
        style: this.effectStyle,
        class: [this.aspectRatio, { 'h-full': this.appearance === 'fill' }],
      }
    },
  },

  watch: {
    imgExists() {
      this.$Lazyload.lazyLoadHandler()
    },
  },
}
</script>

<style lang="postcss">
.shadow-filter {
  filter: drop-shadow(0 0.6px 2.2px rgba(0, 0, 0, 0.02))
    drop-shadow(0 1.3px 5.3px rgba(0, 0, 0, 0.028))
    drop-shadow(0 2.5px 10px rgba(0, 0, 0, 0.035))
    drop-shadow(0 4.5px 17.9px rgba(0, 0, 0, 0.042))
    drop-shadow(0 8.4px 33.4px rgba(0, 0, 0, 0.05))
    drop-shadow(0 20px 80px rgba(0, 0, 0, 0.07));
}
</style>
