export default [
  {
    key: 'circle',
    label: 'Circle',
  },
  {
    key: 'square',
    label: 'Square',
  },
  // {
  //   key: 'triangle-up',
  //   label: 'Triangle, up',
  //   clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
  // },
  // {
  //   key: 'triangle-down',
  //   label: 'Triangle, down',
  //   clipPath: 'polygon(100% 0%, 0 0, 50% 100%)',
  // },
  // {
  //   key: 'paralellogram-right',
  //   label: 'Paralellogram, right',
  //   clipPath: 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)',
  // },
  // {
  //   key: 'paralellogram-left',
  //   label: 'Paralellogram, left',
  //   clipPath: 'polygon(0 0, 75% 0%, 100% 100%, 25% 100%)',
  // },
  // {
  //   key: 'rhombus',
  //   label: 'Rhombus',
  //   clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
  // },
  // {
  //   key: 'pentagon',
  //   label: 'Pentagon',
  //   clipPath: 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)',
  // },
  // {
  //   key: 'hexagon',
  //   label: 'Hexagon',
  //   clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
  // },
]
